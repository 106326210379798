import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth,scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl,categoryURL} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';



class Edit extends Component {

	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const id = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
            id: id,
			name:'',
			Loading:false,
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			read_only:true,
			full_control: false,
			checkedState: [],
			modulelist:'',
		};
		// this.props.getCategoryDetailList(id);
		var qs = require('qs');
		var catid = this.state.id;
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+"rolesmanagement/listdetail?id="+catid+'&my_id='+admin_id).then(res => {
	
			var checkmodules = res.data.checkmodules;
			var rawmodules = res.data.modulelist;

			this.setState({name:res.data.list.name,checkedState:checkmodules,modulelist:rawmodules});

			//'checkedState','modulelist',

			if(res.data.list.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}
			if(res.data.list.subadmin_privilege === 'grant'){
				this.setState({read_only:false, full_control: true});
			}else{
			this.setState({read_only:true, full_control: false});
			}
		}); 
		
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);

    }

    handleChange = selectedOption => {

        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
	  if(name === 'access_control'){
		this.setState({full_control: !this.state.full_control});
		this.setState({read_only: !this.state.read_only});
     }	
    }
	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		reader.onloadend = () => {
		  this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file
		  });
		};
		reader.readAsDataURL(file);
	}

	componentDidMount() {
		document.title = PageTitle('Category Edit');
    }

	
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
		
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				if(formPayload.read_only){
					var control = 'readonly';
				}else{
					var control = 'grant';
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					name: formPayload.name,
					subadmin_privilege: control,
					status:  status,
					id: formPayload.id,
					selected_module:formPayload.checkedState,
				};
				console.log(postObject,'-postObject')
				axios.post(apiUrl+"Rolesmanagement/edit",qs.stringify(postObject)).then(res => {
					if(res.data.status === "success"){	
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									$('.success_message').html(this.props.history.push('/rolemanagement'));
								}
								.bind(this),
								3000
								);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		const {name} = this.state;
		let errors = {};
      	let formIsValid = true;
		
		if (!name) {
			formIsValid = false;
			$('.errorname').html('<span class="errorspan">Please fill the field</span>');
		}else if(name){
			$('.errorname').html('');
		}

		
		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    componentWillReceiveProps(Props){


   	if(Props.categorydetails !== this.props.categorydetails){
   		
    		if(Object.keys(Props.categorydetails).length > 0){
				this.setState({Loading:false});

					if(Props.categorydetails[0].status === "success"){

						const formdata = Props.categorydetails[0].categorylist;
						
						if(formdata.status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}

						if( parseInt (formdata.form_builder_id) !==0 ){

							this.setState({forms: 1,products:0});
							this.setState({form_select: 'forms'});
						}else{
							this.setState({forms: 0,products:1});
							this.setState({form_select: 'products'});
						}
		

						this.setState({image_preview:formdata.image});
						this.setState({name:formdata.name});
						this.setState({description: formdata.description});						
						this.setState({short_description: formdata.short_description});
                        this.setState({id: formdata.id});


                        
					}
    		}
    	}

    	if(Props.categoryedit !== this.props.categoryedit){
    		if(Object.keys(Props.categoryedit).length > 0){
				if(Props.categoryedit[0].status === "success"){
    					this.setState({ Loading: false });

    			const formpayload = Props.categoryedit[0].categorylist;

				if(formpayload.status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
					}else{
					this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});	
					}	
					this.setState({image_preview:formpayload.image});
					this.setState({name:formpayload.name});
					this.setState({description: formpayload.description});						
					this.setState({short_description: formpayload.short_description});
					this.setState({id: formpayload.id});
			
                    	$('.success_message').html('<div class="status_sucess"><h3>'+ Props.categoryedit[0].message+'</h3></div>');
               			scrollToTop();
    					setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/category'));
						}
						.bind(this),
						3000
						);
						
    		}else{
					this.setState({ Loading: false });
					$('.success_message').html('<div class="status_sucess"><h3>'+ Props.categoryedit[0].message+'</h3></div>');
					scrollToTop();
    					setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
			}
			}
    	}
		
    }

	handleOnChange = (i,event) => {
        var id = event.target.value;
        var idArr = [...this.state.checkedState];
     
        const index = idArr.findIndex(list => list === id);
        if(index > -1) {

        idArr = [...idArr.slice(0, index), ...idArr.slice(index + 1)]
        } else {
        idArr.push(id);
        }
      
        
        this.setState({checkedState: idArr});

        };

  render() {


  	  const {selectedOption,list} = this.state;

  	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="rolemanagement" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Role Management</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Role Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="name" onChange={this.handleInputChange} className="form-control" value={this.state.name} readOnly/>
					<div className="errorname"></div>
				</div>
				<div className="form-group">
					<label>Grant Privileges
					: </label><br />
					<input onChange={this.handleInputChange} type="radio" name="access_control" checked={this.state.read_only}/>
					<span>Read Only</span>
						<input onChange={this.handleInputChange} type="radio"  name="access_control" checked={this.state.full_control}/>
					<span>Grant Full Access (Add, Edit, Delete, View)</span>
 				</div> 	
			</div>

			<div className="form-right">
               <div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
	           </div> 

			</div>	

			<div className="form-group">
                        <h2>Modules Permission</h2>
                        {this.state.modulelist && Object.keys(this.state.modulelist).length > 0 && this.state.modulelist.map(({label, value}, index) => {
                                    return (<> 
                                        <div className="custom_checkbox">
                                        <input
                                        type="checkbox"
                                        id={`custom-checkbox-${index}`}
                                        name={label}
                                        value={value}
                                        checked={this.state.checkedState.includes(value)}
                                        onChange={this.handleOnChange.bind(this,index)}
                                        />
                                        <span></span>
                                        <label htmlFor={`custom-checkbox-${index}`}>{label}</label>
                                        </div>
                                    </>)})}
                        </div>  
                  
			
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


// const mapStateTopProps = (state) => {
//   return {
//     categorydetails: state.categorydetails,
//     categoryedit: state.categoryedit
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getCategoryDetailList: (id) =>{
//        dispatch({ type: GET_CATEGORYDETAILLIST,id });
//     },
//     getEditCategory: (formPayload) =>{
//        dispatch({ type: GET_EDITCATEGORY, formPayload});
//     }
//   }
// }
export default (withRouter(Edit));